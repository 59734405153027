<template>
	<div>
		<div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px;height: auto;"
			align="center">
			<div align="center">
				<img :title='author.content' :src="author.avatar" style="width: 100px;border-radius: 10%;" alt="">
				<h4 :title='author.content' style="color: var(--primary-color);">{{author.name}}</h4>
				<p :title='author.content' style="font-weight: 600;font-size: 14px;">
					{{author.title}}<br>{{author.work}}
				</p>
				<div title="click and contact me for code or any question">
					<a target="_blank" :href="author.facebook"><Button icon="pi pi-facebook"
							class="p-button-rounded mr-2 mb-2" /></a>
					<a target="_blank" :href="author.twitter"><Button icon="pi pi-twitter"
							class="p-button-rounded p-button-info mr-2 mb-2" /></a>
					<Button icon="pi pi-whatsapp" @click="toggleWhatsApp" class="p-button-rounded p-button-success mr-2 mb-2"></Button>
					<OverlayPanel ref="whats_app" appendTo="body" :showCloseIcon="true">
						<img src="images/my_whatsapp.jpg" style="width: 300px;" />
					</OverlayPanel>
					<Button icon="pi pi-weixin" @click="toggle" class="p-button-rounded p-button-success mr-2 mb-2"><i
							class="fa fa-weixin" aria-hidden="true"></i></Button>
					<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
						<img src="images/my_wexin.jpg" style="width: 300px;" />
					</OverlayPanel>
					<Button icon="pi pi-envelop" @click='show_email=!show_email'
						class="p-button-rounded p-button-warning mr-2 mb-2"><i class="fa fa-envelope"
							aria-hidden="true"></i></Button>
				</div>
				<div v-if='show_email' class="p-inputgroup">
					<InputText title='my email address' type="text" :value='author.gmail' placeholder="Price" />
					<span title="close" class="p-inputgroup-addon" @click='show_email=false'><i class="pi pi-times"
							aria-hidden="true"></i></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	export default {
		name: '',
		components: {

		},
		data() {
			return {
				show_email: false,
				author: {
					'avatar': 'images/avatar/weichaoxu.jpg',
					'name': 'Chaoxu Wei',
					'title': 'Master student at Tongji University',
					'work': '(Developer & Blogger)',
					'content': 'Job hunting, both part-time and full-time job are accepted.',
					'twitter': 'https://twitter.com/Joker75342012?t=lvUwjAYZEp_3vRSNOmL0XA&s=09',
					'facebook': 'https://www.facebook.com/profile.php?id=100083516062282',
					'gmail': 'weichaoxu1998@gmail.com',
				}
			}
		},
		networkService: null,
		created() {
			//实例化NetWorkService对象
		},
		mounted() {

		},
		methods: {
			toggle(event) {
				this.$refs.op.toggle(event);
			},
			toggleWhatsApp(event){
				this.$refs.whats_app.toggle(event);
			}
		},
	}
</script>

<style scoped lang="scss">
</style>
