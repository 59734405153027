<template>
	<div style="margin-bottom: 20px;" align="center">
		<ins class="adsbygoogle" style="display:block;" data-ad-client="ca-pub-8147879111198627"
			data-ad-slot="4026586674" data-ad-format="auto" data-full-width-responsive="true"></ins>
	</div>
	<div class="card grid" style="justify-content: space-around;">
		<!-- <div class="card col-12 md:col-12"> -->
		<dvi class="col-12 md:col-7">
			<iframe ref='pdfjs_viewer_1' name="pdf_container" id="pdf_container"
				:src="'pdfjs-cors/web/viewer.html?file='+pdf_path" frameborder="0" width="100%"
				:height="pdf_height"></iframe>
		</dvi>
		<div class="col-12 md:col-3" align="center" style="margin-top: -10px;">
			<div class="p-3">
				<AdminInfo></AdminInfo>
			</div>
			<div class="p-3">
				<div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px;height: auto;"
					align="center">
					<InputText style="width: 100%;margin-bottom: 20px;" title='my email address' type="text"
						v-model='pdf_url' placeholder="Input PDF file url" />
					<Button @click='addPdfUrl()' style="width: 100%;" label="test load file from url"
						class="p-button-info mb-3" />
				</div>
			</div>
			<div class="p-3">
				<div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px;height: auto;"
					align="center">
					<Button @click='goAnnotation()' title='Add annotation functions for pdf.js 给pdf.js增加批注功能'
						icon="pi pi-external-link" style="width: 100%;" label="Get annotation plugin"
						class="p-button-warning mb-3" />
					<a :href="source_code_url" target="_blank">
						<Button icon="pi pi-arrow-down" style="width: 100%;" label="Download source code"
							class="p-button-success mb-3" />
					</a>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-2" style="margin-top: 10px;" align="center">
			<ins class="adsbygoogle" style="display:inline-block;width:160px;height:600px"
				data-ad-client="ca-pub-8147879111198627" data-ad-slot="2871419116"></ins>
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	import AdminInfo from './AdminInfo.vue';
	export default {
		name: '',
		components: {
			// 'GoogleAds': GoogleAds,
			'AdminInfo': AdminInfo,
		},
		data() {
			return {
				pdf_path: 'example.pdf',
				pdf_url: null,
				pdf_height: "720px",
				source_code_url: 'http://43.135.160.161:8888/down/6aZy92CvBG7S',
				pay_src: 'https://paypal.me/weichaoxu?country.x=C2&locale.x=zh_XC',
			}
		},
		// networkService: null,
		created() {
			//实例化NetWorkService对象
			// this.networkService = new NetWorkService();
		},
		mounted() {
			window.addAds();
			window.addAds();
			// let vm = this;
			// vm.resizeWin();
			// //屏幕变化时
			// window.onresize = () => {
			// 	return (() => {
			// 		vm.resizeWin();
			// 	})();
			// };
		},
		methods: {
			//屏幕变化调整样式
			resizeWin() {
				// this.pdf_width = document.documentElement.scrollWidth-400+'px';
				var windowHeight = document.documentElement.clientHeight;
				if (windowHeight > 830) {
					this.pdf_height = '720px';
				} else {
					this.pdf_height = windowHeight - 110 + 'px';
				}
				// console.log('高度',this.pdf_height);
			},
			goAnnotation() {
				this.$router.push({
					path: '/pdfjs-annotation',
				})
			},
			addPdfUrl() {
				// https://fireflycos.libertynlp.com/firefly-static/new_shouce.pdf
				this.pdf_path = this.pdf_url;
				// console.log(this.pdf_url);
				// this.pdf_path='https://fireflycos.libertynlp.com/firefly-static/new_shouce.pdf';
			}
		},
	}
</script>

<style scoped lang="scss">
</style>
